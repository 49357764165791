<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <table-header
        ref="header"
        :title="title"
        :hide-search="false"
        :searchTips="$t('device_view.search_tips')"
        @runSearch="runSearch"
      />
      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        :permission="view_permission"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
      />
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'

export default {
  name: 'callBellRecord',
  components: {
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('callBellRecord'),
      view_permission: common.checkPermission('CallBell_Menu_Record'),
      // view_permission: false,,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'member.chineseName',
          field: 'memberName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar
            if (row.memberPhoto) {
              avatar = common.getServerUrl() + row.memberPhoto
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'call_bell.prompt_time',
          field: 'armedTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: 'call_bell.follow_up_time',
          field: 'followedTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: 'call_bell.follow_up_user',
          field: 'followedStaff',
          width: '190px',
        },
        {
          label: 'call_bell.confirm_status',
          field: 'isConfirmed',
          width: '100px',
          change: (isConfirmed) => isConfirmed ? 'true' : 'false',
          changeHTML: (value, _row) => {
            return value === 'true' ? this.$t('call_bell.confirm_status_positive') : this.$t('call_bell.confirm_status_pending')
          }
        },
        // {
        //   label: 'common.action',
        //   field: 'action',
        //   width: '200px',
        //   sortable: false,
        // },
      ],
      table_actions: [],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'isConfirmed',
          label: 'call_bell.confirm_status',
          type: 'select',
          options: [{
            text: this.$t('call_bell.confirm_status_positive'),
            value: true
          }, {
            text: this.$t('call_bell.confirm_status_pending'),
            value: false
          }],
          clearable: true,
        },
      ],
      filterValue: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID') ?? null,
        roomID: common.getCustomConfigByKey('roomID') ?? null,
      },
    }
  },

  computed: {
    callBellToastNum: () => store.state.app.callBellToastNum,
  },

  watch: {
    callBellToastNum(newValue, oldValue) {
      if(newValue === 0 && oldValue > 0) {
        this.getList()
      }
    }
  },

  activated() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
      this.getList()
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', this.filterValue.companyID, this)
      common.getSearchFloorOptions('floorID', this.filterValue.facilityID, this)
      this.getList()
    }
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      this.$requestWehealth({
        method: 'get',
        url: '/callbell/GetArmedList',
        params: Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.callBells
        })
        .catch(err => {
          console.log(err)
        })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()
      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      Object.assign(where, this.$refs.search.search_condition)
      return where
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>
